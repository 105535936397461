/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_z2nusry",
        "template_eilq3go",
        form.current,
        "1l6kPo-ltLzrWWQgI"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="min-h-screen flex justify-center">
      <div className="w-1/2">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d301.19447955070143!2d29.416602372218367!3d40.79325666467854!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cadf87403932dd%3A0x526c74f8c157d895!2sOsman%20Y%C4%B1lmaz%2C%20621.%20Sk.%20No%3A8%2C%2041400%20Gebze%2FKocaeli!5e0!3m2!1str!2str!4v1681023909455!5m2!1str!2str"
          width="100%"
          height="100%"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="w-1/2">
        <form
          className="bg-gray-400 w-[50vw] h-screen shadow-md rounded px-8 pt-6 pb-8 flex flex-col justify-center"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="mb-4">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="İsminiz"
              name="user_name"
            />
          </div>
          <div className="mb-4">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Email adresiniz"
              name="user_email"
            />
          </div>
          <div className="mb-4">
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="message"
              placeholder="Mesajınız"
              name="message"
            ></textarea>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Gönder
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import "./footer.css"

const Footer = () => {
  return (
    <footer class="footer">
      <p>© 2023 My Portfolio Website. All rights reserved.</p>
      <small>
        Designed and developed by <a href="/">Muhammed Beraat Kıldacı</a>
      </small>
    </footer>
  );
};

export default Footer;

import React from "react";

const About = () => {
  return (
    <div className="sm:flex mb-20 mt-10 sm:mx-5 md:mx-10 lg:mx-20">
      <div className="sm:w-1/2 mt-10 flex justify-center items-center">
        <img
          src="https://pbs.twimg.com/profile_images/1203647407273185280/Unp3MrAi_400x400.jpg"
          alt="Muhammed Beraat Kıldacı"
          className="w-1/2 border-4 border-white rounded-3xl shadow-lg"
        />
      </div>
      <div className="sm:w-1/2 flex justify-center items-center mb-5 sm:mb-0">
        <div className=" text-gray-100">
          <h3>Merhaba! </h3><br/>
          Ben, 21/09/2018 tarihinde Gebze Anadolu İmam Hatip
          Lisesi'nden mezun oldum. Ardından, 25/05/2019 tarihinde Gebze Halk
          Eğitim Merkezi ve Dijital Dönüşüm Merkezi tarafından düzenlenen Nesne
          Tabanlı Programlamacı Kursuna katıldım. Bu kurs, yazılım dünyasıyla
          tanışmama ve onun hayatımın bir parçası haline gelmesine neden oldu.
          Daha sonra, Oyunla Gelecek Yaz Kampı'na katılarak bu alandaki
          becerilerimi geliştirmeye devam ettim.<br/> <br/> 23/09/2019 tarihinde Zonguldak
          Bülent Ecevit Üniversitesi Devrek Meslek Yüksekokulu'nda eğitimime
          başladım ve burada öğrendiklerimi pratikte uygulama fırsatı buldum.
          28/06/2021 tarihinde 3.29 diploma notu ile mezun oldum.<br/> <br/> Yazılım
          dünyasına olan merakım ve heyecanım, bana bu alanda kendimi sürekli
          geliştirmek için ilham veriyor.
        </div>
      </div>
    </div>
  );
};

export default About;

import React from "react";
import ExperienceCard from "../../components/ExperienceCard/ExperienceCard";

const Experience = () => {
  return (
    <div className="container mx-auto p-12">
      <h2 className="w-full h-full bg-orange-500 rounded p-5 text-gray-50 text-2xl font-semibold">İş deneyimlerim:</h2>
      <div className="mt-5 flex flex-wrap -mx-2 mt-5">
      <ExperienceCard
        title="Stajyer Yazılım Geliştirici"
        company="Metix Bilişim"
        date="2 ay"
        description="Crm projeleri erp projeleri gibi bir çok alanda yazılım üreten firmada stajyer yazılım geliştirici olarak çalıştım."
      />
      <ExperienceCard
        title="Web Geliştirici"
        company="Kıldacı Gençlik Spor Kulübü"
        date="1 ay"
        description="Spor salonu web sitesi geliştirilmesi"
      />
      <ExperienceCard
        title="Web Geliştirici"
        company="Emek Soft"
        date="1 ay"
        description="Çıralı Rose Bungalov otelinin web sitesi geliştirilmesi"
      />
      </div>
    </div>
  );
};

export default Experience;

import React from "react";
import useTypewriter from "react-typewriter-hook";
import { motion } from "framer-motion";

const HeroSection = () => {
  const name = "Muhammed Beraat Kıldacı";

  const typing = useTypewriter(name);

  return (
    <div className="w-[100vw] h-[80vh] sm:flex mb-10 mt-10 sm:mx-5 md:mx-10 lg:mx-20">
      <div className="sm:w-1/2 flex justify-center items-center mb-5 sm:mb-0">
        <div className="text-center text-gray-100">
          <h1 className="text-3xl font-bold text-center">{typing}</h1>
          <motion.h1
            className="text-2xl text-green-400"
            animate={{ rotateY: 360, scale: 1.2 }}
            transition={{ duration: 5, repeat: Infinity, repeatType: "mirror" }}
          >
            Yazılım Geliştirici
          </motion.h1>
        </div>
      </div>

      <div className="sm:w-1/2 mt-10 flex justify-center items-center lg:w-full">
        <img
          src="https://avatars.githubusercontent.com/u/69050690?v=4"
          alt="Profile"
          className="w-1/2 border-4 border-white rounded-full shadow-lg"
        />
      </div>
    </div>
  );
};

export default HeroSection;

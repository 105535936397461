import React from 'react';
import Projects from './pages/Projects/Projects';
import HeroSection from './components/HeroSection/HeroSection';
import Navigation from './components/Navigation/Navigation';
import { Route, Routes } from 'react-router-dom';
import SocialMedia from './components/SocialMedia/SocialMedia';
import Home from './pages/Home/Home';
import Experience from './pages/Experience/Experience';
import Contact from './pages/Contact/Contact';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className='bg-slate-800'>
      <Navigation/>
      <SocialMedia/>
      <HeroSection/>
      <div className='bg-gray-700'>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/projects' element={<Projects/>}/>
        <Route path='/experience' element={<Experience/>}/>
        {/* <Route path='/blogs' element={<Blogs/>}/> */}
        <Route path='/contact' element={<Contact/>}/>
      </Routes>
      </div>
      <Footer/>
    </div>
  );
}

export default App;

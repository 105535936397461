import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaGithub, FaMediumM } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import "./social-media.css" 

function SocialMedia() {
  return (
    <div className="w-10 mx-2 flex flex-col items-center justify-center social-media-group">
      <Link to="https://www.facebook.com/berat.kildaci/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-800">
        <FaFacebookF className="w-6 h-6 m-2 text-green-300" />
      </Link>
      <Link to="https://www.instagram.com/beraat.kildaci/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-800">
        <FaInstagram className="w-6 h-6 m-2 text-green-300" />
      </Link>
      <Link to="https://www.linkedin.com/in/muhammed-beraat-k%C4%B1ldac%C4%B1-3576a6187/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-800">
        <FaLinkedinIn className="w-6 h-6 m-2 text-green-300" />
      </Link>
      <Link to="https://twitter.com/83afd2b408ed4a1" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-800">
        <FaTwitter className="w-6 h-6 m-2 text-green-300" />
      </Link>
      <Link to="https://github.com/MuhammedBeraatKildaci" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-800">
        <FaGithub className="w-6 h-6 m-2 text-green-300" />
      </Link>
      <Link to="https://medium.com/@muhammedberaatkildaci" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-800">
        <FaMediumM className="w-6 h-6 m-2 text-green-300" />
      </Link>
    </div>
  );
}

export default SocialMedia;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Star } from 'react-feather';


const Projects = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.github.com/users/MuhammedBeraatKildaci/repos")
      .then((response) => setData(response.data))
      .catch((error) => console.log(error));

  }, []);

  return (
    <div className="container mx-auto p-12 ">
        <div className="w-full h-full bg-orange-500 rounded p-5 text-gray-50 text-2xl font-semibold">Projelerim</div>
      <div className="flex flex-wrap -mx-2 mt-5">
        {data.map((item) => (
          <div key={item.id} className="w-full sm:w-1/2 xl:w-1/3 px-2 mb-4 ">
            <div className="bg-white rounded-lg overflow-hidden shadow-lg ">
              <div className="bg-slate-600 text-gray-200 font-bold text-base px-4 py-2 ">
                <a className="flex justify-between" href={item.html_url} target="_blank" rel="noreferrer">
                  {item.name}   <span className="flex justify-between w-10"><Star className="text-orange-400"/> {item.stargazers_count}</span>
                </a>
              </div>
              <div className="p-4 bg-slate-500">
                <p className="text-gray-100 text-base">
                  {item.description || ""}
                </p>
                <div className="mt-4 flex justify-between">
                  {item.language !== null ?  <span
                    className={`${
                      item.language === "Java"
                        ? "bg-orange-400"
                        : item.language === "C#"
                        ? "bg-purple-400"
                        : item.language === "C++"
                        ? "bg-pink-400"
                        : item.language === "JavaScript"
                        ? "bg-yellow-400"
                        : item.language === "TypeScript"
                        ? "bg-blue-500"
                        : item.language === "HTML"
                        ? "bg-red-400"
                        : item.language === "CSS"
                        ? "bg-blue-400"
                        : "bg-red-700"
                    } inline-block rounded-full text-white px-3 py-1 text-sm font-semibold`}
                  >
                    {item.language}
                  </span>:""}
                  { <span
                    className={`${
                      item.language === "Java"
                        ? "bg-orange-400"
                        : item.language === "C#"
                        ? "bg-purple-400"
                        : item.language === "C++"
                        ? "bg-pink-400"
                        : item.language === "JavaScript"
                        ? "bg-yellow-400"
                        : item.language === "TypeScript"
                        ? "bg-blue-500"
                        : item.language === "HTML"
                        ? "bg-red-400"
                        : item.language === "CSS"
                        ? "bg-blue-400"
                        : "bg-red-700"
                    } inline-block rounded-full text-white px-3 py-1 text-sm font-semibold`}
                  >
                    <a href={item.html_url}>Projeyi Görüntüle</a>
                  </span>}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;

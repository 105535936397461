import React from "react";

const ExperienceCard = ({ title, company, date, description }) => {
  return (
    <div className="bg-slate-100 rounded-lg shadow-lg py-6 px-8 m-5 w-64">
      <h3 className="text-2xl font-bold text-gray-800">{title}</h3>
      <p className="text-gray-600">{company}</p>
      <p className="text-gray-600">{date}</p>
      <hr className="my-4" />
      <p className="text-gray-800">{description}</p>
    </div>
  );
};

export default ExperienceCard;
